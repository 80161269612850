<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">行业分类</div>
    </div>
    <div class="nav2">
      <div class="myForm">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <!-- <el-form-item label="商品分类：">
            <el-select
              size="small"
              v-model="searchForm.pid"
              placeholder="商品分类"
            >
              <el-option label="顶级分类" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  label="状态：">
            <el-select size="small" v-model="searchForm.status" placeholder="状态">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item  label="二级分类：">
            <el-input
              size="small"
              v-model="searchForm.keyword"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="searchOnSubmit"
              >查找</el-button
            >
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-button size="small" type="primary" @click="setAllNodeExpand"-->
<!--            >展开测试按钮</el-button-->
<!--            >-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-button size="small" type="primary" @click="isNodeExpandByRow"-->
<!--            >判断节点展开状态</el-button-->
<!--            >-->
<!--          </el-form-item>-->
        </el-form>
      </div>
<!--      <div class="tit1">-->
<!--        <el-button @click="AddFenlei" size="small" type="primary" icon="el-icon-plus">添加行业分类</el-button>-->
<!--      </div>-->
      <div class="myTable">
        <vxe-table ref="myTable2" :tree-config="{
          transform: true, line: true,rowField: 'id', parentField: 'parentId',
          lazy: true, hasChild: 'myis_child', loadMethod: loadChildrenMethod
        }"  :data="tableData">
        <!-- <vxe-column type="seq" width="180" title="序号" tree-node></vxe-column> -->
          <vxe-table-column  tree-node width="130" field="sort" title="排序"></vxe-table-column>
          <vxe-table-column  field="title" title="分类名称"></vxe-table-column>
          <vxe-table-column  field="code" title="内部编码"></vxe-table-column>
          <vxe-table-column field="zhilian_names" title="关联智联"  style="line-height: normal"></vxe-table-column>
          <vxe-table-column field="job51_names" title="关联前程无忧"  style="line-height: normal"></vxe-table-column>

          <!-- <vxe-table-column field="pic" title="分类图标">
            <template slot-scope="scope">
              <el-image
                :src="scope.row.pic"
                fit="fill"
                style="width: 40px; height: 40px"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </vxe-table-column>-->
          <vxe-table-column field="is_show" title="状态(是否显示)">
            <template slot-scope="scope">
              <el-switch @change="changeKG(scope.row)" v-model="scope.row.myis_status"></el-switch>
            </template>
          </vxe-table-column>
          <vxe-table-column title="操作" width="180">
            <template slot-scope="scope">
              <div class="flex">
                <el-button v-if="scope.row.parent_level != 0" size="small" type="text" @click="tabEdit2(scope.row,0)">编辑</el-button>
                <el-button v-if="scope.row.parent_level == 2" size="small" type="text" @click="tabEdit2(scope.row,2)">关联智联</el-button>
                <el-button v-if="scope.row.parent_level == 2" size="small" type="text" @click="tabEdit2(scope.row,4)">关联前程无忧</el-button>
                <el-button v-if="scope.row.parent_level != 2" size="small" type="text" @click="addChild(scope.row,0)">添加</el-button>
                <el-button v-if="scope.row.parent_level != 0" size="small" type="text" @click="tabDel(scope.row)">删除</el-button>
              </div>
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-pagination class="fenye" @size-change="this.handleSizeChange" @current-change="this.handleCurrentChange"
          :current-page="this.biaobaiqiangPage" :page-size="10" :page-sizes="[10, 15, 20, 30]"
          layout="total,sizes, prev, pager, next, jumper" :total="this.total"></el-pagination>
      </div>
    </div>
    <!-- 添加/编辑分类 -->
    <el-dialog title="添加/编辑分类" :visible.sync="addDialogVisible" width="700px" :before-close="addHandleClose" destroy-on-close>
      <div class="myAddForm">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-addForm">
          <!-- <el-row>
            <el-col :span="20">
              <el-form-item label="父级">
                <el-select
                  size="small"
                  v-model="addForm.pid"
                  placeholder="请选择"
                >
                  <el-option label="顶级分类" value="0"></el-option>
                  <el-option
                    v-for="item in tableData"
                    :key="item.id"
                    :label="item.cate_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>-->
          <el-row v-show="zpsite_type==0">
            <el-col :span="20">
              <el-form-item label="分类名称" prop="title">
                <el-input size="small" placeholder="请输入分类名称" v-model="addForm.title"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="zpsite_type==0">
            <el-col :span="20">
              <el-form-item v-if="this.isAdd == true" label="内部编码" prop="code">
                <el-input size="small" placeholder="请输入内部编码" v-model="addForm.code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="20">
              <el-form-item label="分类图标(180*180)">
                <div @click="companyList('tb')" class="myImg">
                  <el-image
                    :src="addForm.pic"
                    fit="fill"
                    style="width: 60px; height: 60px"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div @click.stop="delImg('tb')" class="closeBtn">
                    <el-button circle>×</el-button>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="分类大图(468*340)">
                <div @click="companyList('dt')" class="myImg">
                  <el-image
                    :src="addForm.big_pic"
                    fit="fill"
                    style="width: 60px; height: 60px"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div @click.stop="delImg('dt')" class="closeBtn">
                    <el-button circle>×</el-button>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>-->
          <el-row v-show="zpsite_type==0">
            <el-col :span="10">
              <el-form-item v-if="this.isAdd == false" label="排序">
                <el-input size="small" v-model="addForm.sort"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item label="类型">
                <el-radio-group v-model="addForm.type">
                  <el-radio label="服务商品"></el-radio>0
                  <el-radio label="商城商品"></el-radio>1
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row >
            <el-col :span="12">
              <el-form-item label="状态" v-show="zpsite_type==0">
                <el-radio-group v-model="addForm.is_status">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
              <!--       :props="{children: 'children',label: 'title'}"，label是显示的树枝-->
              <el-form-item label="关联行业" v-show="addForm.zpsite_type==1 && addForm.parent_level==2 && (this.zpsite_type==2||this.zpsite_type==4)">
                <el-tree
                        class="stafftree"
                        :data="zhilian_tree"
                        ref="zhilian_trees"
                        show-checkbox
                        node-key="id"
                        :props="{children: 'children',label: 'title'}"
                        :expand-on-click-node="false"
                        :default-checked-keys="zhilian_checkkeys"
                        :default-expanded-keys="zhilian_checkkeys"
                        @check-change="handleCheckChange">
                </el-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button size="small" type="primary" @click="AddOnSubmit('addForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <input type="file" name="companyLogo" id="file0" class="displayN" multiple="multiple" @change="companyLogo($event)"
      ref="fileInputList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["tabShopIndex", "biaobaiqiangPage",
      "biaobaiqiangPageSize",])
  },
  watch: {
    tabShopIndex: function () {
      this.activeName = this.tabShopIndex;
      console.log(this.activeName);
      this.getData();
    },
    biaobaiqiangPage: function (page) {
      this.$store.commit("biaobaiqiangPage", page);
      this.getData();
    },
    biaobaiqiangPageSize: function (pageSize) {
      this.$store.commit("biaobaiqiangPageSize", pageSize);
      this.getData();
    },
  },
  data() {
    return {

      queryTemp:"",//查找暂存

      zhilian_tree:[],//智联树
      zhilian_checkkeys:[],//选中的智联树id集合
      zhilian_checklabel:[],//选中的智联树title集合，通过getCheckedNodes处理
      dealcheckkeys:[],
      dealchecklabels:[],


      activeName: "1",
      searchForm: {
        pid: "",
        status: "",
        keyword: ""
      },
      tableData: [],
      addDialogVisible: false,
      addForm: {
        title: "",
        is_status: 0,
        sort:'',
        code:"",
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入内部编码", trigger: "blur" }],
        is_show: [{ required: true, message: "请选择状态", trigger: "change" }]
      },
      imgStatus: "",
      imgFile: "",
      id: "",
      total: 0,
      isAdd: false,
      zpsite_type: 0,
      row: null,
    };
  },
  created() {
    this.$store.commit("biaobaiqiangPage", 1);
    this.$store.commit("biaobaiqiangPageSize", 10);
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$api.industrylist({
        page: this.biaobaiqiangPage,
        pagesize: this.biaobaiqiangPageSize,
        parent_id: 0,
        // keyword:this.searchForm.keyword,
      });
      console.log(res);
      this.tableData = res.list ? res.list : [];
      this.total = res.total
      this.tableData.forEach(ele => {
        ele.myis_status = ele.is_status == 1 ? true : false;
        ele.myis_child = ele.is_child == 1 ? true : false;
      })
    },
    async reloadBeginRow(){
      this.$refs.myTable2.reloadTreeExpand(this.tableData[0])
      this.$refs.myTable2.reloadTreeExpand(this.tableData[1])
    },
    async setAllNodeExpand(){
      this.$refs.myTable2.setTreeExpand(this.tableData[0],true)
      this.$refs.myTable2.setTreeExpand(this.tableData[1],true)
    },
    // async isNodeExpandByRow(){
    //   if (this.$refs.myTable2.isRowExpandLoaded(this.tableData[0])){console.log("第一条为true")}
    //   if (this.$refs.myTable2.isRowExpandLoaded(this.tableData[1])){console.log("第二条为true")}
    // },
    async getTree() {
      //zhilian_tree是后端传来最原始的el-tree数据，这边做一个只有第三层可选的处理，以此遍历加参
      const restree = await this.$api.industry_zhilian_tree({zpsite_type:this.zpsite_type})
      this.zhilian_tree=restree.tree ? restree.tree : []
      //做一个disabled,以及当智联设置禁用时，智联树数据更新的处理，即取到的禁用节点不再提交，要去除
      this.zhilian_tree.forEach(node=>{
        if (node.parent_level!=3){
          node.disabled=true
        }
        if (node.children){
          node.children.forEach(minNode=>{
            minNode.disabled=true
            if (minNode.children){
              minNode.children.forEach(ministNode=>{
                this.zhilian_checkkeys.forEach(num=>{//
                  if (num == ministNode.id){//
                    this.dealcheckkeys.push(num)//
                    this.dealchecklabels.push(ministNode.title)//
                  }//
                })//
              })
            }
          })
        }
      })
      this.zhilian_checkkeys=this.dealcheckkeys
      this.zhilian_checklabel=this.dealchecklabels
      this.dealcheckkeys=[]
      this.dealchecklabels=[]

      // console.log(this.zhilian_tree)
    },
    async getTree2() {
      //zhilian_tree是后端传来最原始的el-tree数据，这边做一个只有第2层可选的处理，以此遍历加参
      const restree = await this.$api.industry_zhilian_tree({
        zpsite_type:this.zpsite_type
      })
      let tree=restree.tree ? restree.tree : []
      this.zhilian_tree=tree

    },
    handleCheckChange() {//选中树节点处理方法
      // checkedNodeKeys, checkedNodes
      // console.log('Selected Node Keys:', checkedNodeKeys);
      // console.log('Selected Node Details:', checkedNodes);
      this.getSelectedKeys()
      this.getSelectedLables()
    },
    getSelectedLables() {//赋值已选择树节点的名称
      const tree = this.$refs.zhilian_trees;
      // const selectedNodes = tree.getCheckedNodes();
      // 1.获得树对象，2定义空集合，3,获得node.title，push到空集合 4，赋值
      let tempLabels = []
      tree.getCheckedNodes(true).forEach(node=>{
        tempLabels.push(node.title)
      })
      this.zhilian_checklabel=tempLabels
      // console.log('zhilian_checklabel:', this.zhilian_checklabel);
    },
    getSelectedKeys() {//赋值已选择树节点的id
      const tree = this.$refs.zhilian_trees;
      const selectedKeys = tree.getCheckedKeys(true);
      this.zhilian_checkkeys=selectedKeys
      // console.log('zhilian_checkkeys:', this.zhilian_checkkeys);
    },

    async loadChildrenMethod({ row }) {
      // 异步加载子节点
      if (row.parent_level==0){
        const res = await this.$api.industrylist({
          page: 1,
          pagesize: 1000,
          parent_id: row.id,
          // keyword:this.searchForm.keyword,
          keyword: this.queryTemp,
        });
        res.list.forEach(ele => {
          ele.myis_status = ele.is_status == 1 ? true : false;
          ele.myis_child = ele.is_child == 1 ? true : false;
        })
        return res.list
      }else {
        const res = await this.$api.industrylist({
          page: 1,
          pagesize: 1000,
          parent_id: row.id,
          // keyword:this.searchForm.keyword,
        });
        res.list.forEach(ele => {
          ele.myis_status = ele.is_status == 1 ? true : false;
          ele.myis_child = ele.is_child == 1 ? true : false;
        })
        return res.list
      }

    },
    // 开关（显示/隐藏）
    async changeKG(row) {
      console.log(row);
      const res = await this.$api.edit_industry(
        {
          ...row,
          is_status: row.is_status == 0 ? 1 : 0,
          parent_id: row.id,
          parent_level: row.parent_level,
        },
      );
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    tabsHandleClick(tab) {
      console.log(tab.index);
      this.$store.commit("tabShopIndex", (Number(tab.index) + 1).toString());
    },
    searchOnSubmit() {
      console.log(this.searchForm);
      this.queryTemp = this.searchForm.keyword
      this.biaobaiqiangPage = 1
      // this.getData()
      // console.log(this.searchForm.keyword)
      if (this.searchForm.keyword!=null && this.searchForm.keyword != ""){
        this.reloadBeginRow()
        this.setAllNodeExpand()
      }else {
        this.getData();
      }

    },
    AddFenlei() {
      this.isAdd = true
      this.row = null
      for (const key in this.addForm) {
        this.$set(this.addForm, key, "");
      }
      this.addForm.is_status = 1
      this.addDialogVisible = true;
    },
    addHandleClose() {
      this.addDialogVisible = false;
      this.dealcheckkeys=[]
      this.$refs.zhilian_trees.setCheckedKeys([])
    },
    tabEdit(row) {

      console.log(row);
      this.isAdd = false;
      this.id = row.id;
      this.row = row;
      this.addForm = { ...row };
      this.addDialogVisible = true;
    },

    async tabEdit2(row,zpsite_type) {
      console.log(row);
      this.isAdd = false;
      this.zpsite_type=zpsite_type
      this.id = row.id;
      this.row = row;
      this.addForm = { ...row };
      //数组字符串转数组
      let zhilian_ids=row.zhilian_ids
      if (zpsite_type==4){
        zhilian_ids=row.job51_ids
      }
      if (zhilian_ids){
        var nums=zhilian_ids.split(",")//["5", "7"]
        nums.forEach((item,index) =>{
          nums[index] = parseInt(nums[index])
        })
        this.zhilian_checkkeys=nums//[5,7]
        console.log(this.zhilian_checkkeys)
      }else {
        //如果没有zhilian_ids，需要重置zhilian_checkkeys
        this.zhilian_checkkeys=[]
      }
      this.dealcheckkeys=[]
      this.dealchecklabels=[]
      // 有数组后获取智联树
      await this.getTree2();
      //处理当前默认选中和智联树的禁用节点冲突
      this.addDialogVisible = true;
    },
    addChild(row,zpsite_type) {
      this.isAdd = true
      this.zpsite_type=zpsite_type
      this.row = row
      for (const key in this.addForm) {
        this.$set(this.addForm, key, "");
      }
      this.addForm.is_status = 1
      this.addDialogVisible = true;
    },
    async tabDel(row) {
      console.log(row);
      const res = await this.$api.del_industry({ id: row.id });
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.addDialogVisible = false;
        this.getData();
      } else {
        this.$message.error(res.msg);
      }
    },
    AddOnSubmit(formName) {
      console.log(this.addForm);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.isAdd) {
            // 新增
            const res = await this.$api.add_industry({
              ...this.addForm,
              //这几个新增属性都是不能编辑的
              parent_id: this.row ? this.row.id : 0,
              parent_level: this.row ? Number(this.row.parent_level) + 1 : 1,
              parent_code: this.row.code,
              zpsite_type: this.row.zpsite_type,
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
            }
          } else {
            // 编辑
            const res = await this.$api.edit_industry(
              {
                ...this.addForm,
                // parent_id: this.row ? this.row.id : 0,
                // parent_level: this.row.parent_level,
                //转字符串存储
                zpsite_type:this.zpsite_type,
                ids:this.zhilian_checkkeys.toString(),
                names:this.zhilian_checklabel.toString()
              },
            );
            // 已经发完请求，res得到应答
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              // 点击提交按钮后，zhilian_checkkeys初始化
              this.zhilian_checkkeys=[]
              this.getData();
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除图片
    delImg(val) {
      if (val == "tb") {
        this.$set(this.addForm, "pic", "");
      } else if (this.imgStatus == "dt") {
        this.$set(this.addForm, "big_pic", "");
      }
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("biaobaiqiangPageSize", val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("biaobaiqiangPage", val);
    },
    // 上传图片
    companyList(val) {
      this.imgStatus = val;
      this.$refs.fileInputList.click();
    },
    async companyLogo(event) {
      const that = this;
      var file = event.target.files[0];
      var fileSize = file.size; //文件大小
      var filetType = file.type; //文件类型
      //创建文件读取对象
      // console.log(file);
      if (fileSize <= 10240 * 1024) {
        if (
          filetType == "image/png" ||
          filetType == "image/jpeg" ||
          filetType == "image/gif"
        ) {
          this.imgFile = new FormData();
          this.imgFile.append("image[]", file);
          sessionStorage.setItem("img", 123);
          const res = await that.$api.productUpload(this.imgFile);
          console.log(res.data[0]);
          if (this.imgStatus == "tb") {
            this.$set(this.addForm, "pic", res.data[0]);
          } else if (this.imgStatus == "dt") {
            this.$set(this.addForm, "big_pic", res.data[0]);
          }
          that.$refs.fileInputList.value = "";
        } else {
          this.$message.error("图片格式不正确");
        }
      } else {
        this.$message.error("图片大小不正确");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.index {}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }
}

.nav2 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    ::v-deep  .el-form-item__label {
      font-size: 12px;
    }

    ::v-deep  .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  .tit1 {
    margin-top: 10px;
  }

  .myTable {
    margin-top: 10px;

    ::v-deep  .vxe-table--render-default .vxe-body--column {
      vertical-align: middle;
    }

    ::v-deep  .vxe-cell--label {
      font-size: 12px;
    }

    ::v-deep  .vxe-cell--title {
      font-size: 12px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.myAddForm {
  ::v-deep  .el-select {
    width: 100%;
  }

  ::v-deep  .el-form-item__label {
    font-size: 12px;
    width: 130px !important;
  }

  ::v-deep  .el-form-item__content {
    margin-left: 130px !important;
  }

  ::v-deep  .el-radio__label {
    font-size: 12px;
  }

  ::v-deep  .el-button {
    width: 100%;
  }

  .myImg {
    position: relative;
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-right: 12px;

    .closeBtn {
      position: absolute;
      top: -6px;
      right: -4px;
      width: 20px;
      height: 20px;

      .el-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ::v-deep  .image-slot {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fafafa;
      width: 58px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-picture-outline {
        font-size: 20px;
      }
    }
  }
}

.displayN {
  display: none;
}
.fenye{
  margin-top: 20px;
}
::v-deep  .vxe-table--render-default .vxe-tree--line{
  bottom: 0.1em !important;
}


/* 隐藏掉el-tree中有disabled属性的框框 */
/* .stafftree为el-tree的类名 */
::v-deep .stafftree {
  // 不可全选样式
  .el-tree-node {
    .el-checkbox__input.is-disabled {
      display: none;
    }
  }
}

</style>
